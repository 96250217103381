<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in banner" :key="item">
        <img class="hospital-banner" :src="item" alt="" />
      </van-swipe-item>
    </van-swipe>

    <div class="wrap">
      <entrance :linkDetail="hospitalInfo.linkDetail"></entrance>
      <hospitalInfo :hospitalInfo="hospitalInfo"></hospitalInfo>
    </div>
  </div>
</template>

<script>
import hospitalInfo from "@/views/components/group-index/hospital-info.vue";
import entrance from "@/views/components/group-index/entrance.vue";

import hospitalBanner from "@/assets/hospital-banner.png";
import { getEnterpriseNavigationInfo } from "@/api";
export default {
  components: {
    hospitalInfo,
    entrance,
  },
  computed: {
    hospitalId() {
      return this.$store.state.hospitalId;
    },
    banner() {
      if (this.hospitalInfo?.imageUrls) {
        return this.hospitalInfo.imageUrls;
      } else {
        return [this.hospitalBanner];
      }
    },
  },
  data() {
    return {
      hospitalBanner,
      hospitalInfo: {},
    };
  },
  async mounted() {
    if (localStorage.getItem("hospitalId") != this.$route.query.hospitalId) {
      localStorage.setItem("token", "");
      localStorage.setItem("telNo", "");
      localStorage.removeItem("hospitalInfo");
    }
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
    this.getHospital();
  },
  methods: {
    async getInfo() {
      const res = await getEnterpriseNavigationInfo({
        hospitalCode: this.hospitalId,
      });
      this.hospitalInfo = res;
    },
    async getHospital() {
      let data = await getEnterpriseNavigationInfo({
        HospitalCode: this.hospitalId,
      });
      this.$setTitle(data?.hospitalName || '团检合作');
      this.$store.commit("setTitle", data?.hospitalName || '团检合作');
      if(!data) return
      this.hospitalInfo = data;
      localStorage.setItem("hospitalInfo", JSON.stringify(data));
      localStorage.setItem("telNo", data.telNo);
      this.$store.commit("setHospitalInfo", data);


      return data;
    },
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4;
}
</style>

<style scoped>
.hospital-banner {
  width: 100%;
  height: 48vw;
}

.wrap {
  padding: 10px 10px 24px 10px;
}
</style>