<template>
  <div class="container" v-if="Object.keys(hospitalInfo).length">
    <div style="padding: 20px 10px 0 10px" class="header">
      <img
        class="hospital-logo"
        :src="hospitalInfo.hospitalLogoUrl | filterImg"
        alt=""
      />
      <div style="flex: 1; margin-top: 5px; margin-left: 10px">
        <span class="hospital-name">{{ hospitalInfo.hospitalName }}</span>
        <div style="display: flex">
          <div
            class="hospital-category"
            v-if="
              hospitalInfo.showHospitalLevel && hospitalInfo.hospitalCategory
            "
          >
            {{ hospitalInfo.hospitalCategory }}
          </div>
          <div class="hospital-tag" v-if="advanceDays">
            {{ advanceDays }}
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 0 10px 20px 10px">
      <div class="popup-header">机构地址</div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: start;
        "
        class="popup-text"
      >
        <div style="flex: 1; display: flex">
          <img
            class="icon-location"
            src="@/assets/icon-location.png"
            alt=""
            @click="openLocation"
          />
          {{ hospitalInfo.area }}{{ hospitalInfo.address }}
        </div>

        <div class="address-right">
          <div class="distance" @click="openLocation">
            <img
              class="icon-location-primary"
              src="@/assets/icon-location-primary.png"
              alt=""
            />
            <span>导航</span>
          </div>
          <div class="divider"></div>

          <div class="copy">
            <img
              class="icon-copy"
              src="@/assets/icon_copy.png"
              alt=""
              @click="copyAddress"
            />
            <span>复制</span>
          </div>
        </div>
      </div>
      <div class="popup-header">营业时间</div>
      <div class="popup-text" style="display: flex;">
        <img class="icon-time" src="@/assets/icon-time.png" alt="" />
        {{ hospitalInfo.workTime }}
      </div>
      <template v-if="hospitalInfo.telNo">
        <div class="popup-header">医院电话</div>
        <div class="popup-text">
          <img class="icon-time" src="@/assets/icon-tel.png" alt="" />{{ hospitalInfo.telNo }}
        </div> 
      </template>
      <template v-if="hospitalInfo.briefIntroduce">
        <div class="popup-header">机构介绍</div>
        <div class="popup-text" v-html="hospitalInfo.briefIntroduce"></div>
      </template>
    </div>
  </div>
</template>

<script>
import WxUtil from "@/utils/wxUtils.js";
import { isNull } from "@/utils/index.js";
export default {
  props: {
    hospitalInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    advanceDays() {
      if (isNull(this.$props.hospitalInfo.advanceDays)) return;
      const day = this.$props.hospitalInfo.advanceDays;
      if (!this.$props.hospitalInfo) return;
      if (day == 0) {
        return "当天可约";
      } else {
        return `提前${day}天预约`;
      }
    },
  },
  methods: {
    //打开地图
    openLocation() {
      const hospitalInfo = this.$props.hospitalInfo;
      const latitude = hospitalInfo.latAndLon.split(",")[1];
      const longitude = hospitalInfo.latAndLon.split(",")[0];
      WxUtil.lookLocation({
        latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
        name: hospitalInfo.hospitalName, // 位置名
        address: hospitalInfo.address, // 地址详情说明
        scale: 14, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    //复制地址
    copyAddress() {
      let that = this;
      const text = (this.hospitalInfo.areaName || '') + this.hospitalInfo.address

      this.$copyText(text).then(
        function (e) {
          that.$toast("已复制地址");
        },
        function (e) {
          that.$toast("复制失败请重试");
        }
      );
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
}
.hospital-info-wrapper {
  position: relative;
  margin-top: -36px;
  padding: 0 10px;
  z-index: 2;
}
.hospital-info {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 14px 15px;
}

.hospital-logo {
  width: 54px;
  height: 54px;
  border-radius: 6px;
}

.hospital-name {
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
  line-height: 20px;
}

.hospital-category,
.hospital-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 4px;
  font-size: 13px;
  font-weight: bold;
  background-color: #fff;
  color: #00c4b3;
  border: 1px solid rgba(49, 207, 150, 0.2);
  border-radius: 4px;
}

.footer {
  padding: 15px;
}

.hospital-address,
.hospital-worktime {
  display: flex;
  align-items: center;
  color: #00c4b3;
}

.hospital-worktime {
  justify-content: space-between;
  margin-top: 10px;
}
.address-name,
.work-time {
  font-size: 13px;
  line-height: 14px;
  color: #475266;
}

.icon-time {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-top: 3px;
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.icon-location {
  width: 11px;
  height: 13px;
  margin-right: 5px;
  margin-top: 2px;
}

.icon-location-primary {
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
}

.icon-copy {
  margin-bottom: 2px;
  width: 14px;
  height: 14px;
}

.icon-right {
  margin-left: 2px;
  width: 8px;
  height: 8px;
}
.popup {
  max-height: 80vh;
  overflow-y: scroll;
}

.popup-header {
  margin: 30px 0 10px 0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 16px;
}
.popup-text {
  font-size: 14px;
  font-weight: 400;
  color: #878d99;
  line-height: 20px;
}

.address-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  margin-left: 20px;
}

.work-time {
  display: flex;
  flex: 1;
}

.distance {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #878d99;
  font-size: 12px;
}

.copy {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #878d99;
  font-size: 12px;
}

.divider {
  margin: 0 10px;
  width: 1px;
  height: 20px;
  background: #edeff0;
}
</style>