<template>
  <div class="card-container" v-if="linkDetail && linkDetail.length">
    <div
      class="item"
      v-for="(item, index) in linkDetail"
      :key="index"
      @click="jump(item)">
      <img class="image" :src="item.backgroundUrl" alt="" />
      <div class="item-text">
        <div class="title">{{ item.title }}</div>
        <div class="description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    linkDetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    jump({ url }) {
      if (!url) {
        return this.$toast("敬请期待...");
      }
      if (!url.includes("wx.glztj.com")) {
        location.href = url;
      } else {
        const route = url.split("#")[1];
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.item {
  display: flex;
  position: relative;
  width: calc(50% - 5px);
}

.item:nth-child(n + 3) {
  margin-top: 10px;
}

.image {
  width: 100%;
}

.item-text {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
  font-weight: bold;
}

.description {
  margin-top: 5px;
  font-size: 14px;
  color: #ffffff;
  line-height: 16px;
}
</style>
